
import {defineComponent} from 'vue';
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';

import {VSelect} from "vuetify/components";
import {mapActions, mapGetters, mapMutations} from 'vuex';

import cloneDeep from 'lodash-es/cloneDeep';

// eslint-disable-next-line
import SaveFilterModal from '@app/modules/filter/blocks/SaveFilterModal.vue';
import {makeVideoFilterItems} from "@app/modules/video/video.api";
import {api} from "@app/system/api/api-services";
import {IFilterItemDto, IFilterSetDto} from '@app/system/api/service-proxies/service-proxies';
import {IFilterRow} from "@app/types";
import {getPersonDisplayLabel} from "@app/utils/helpers";

import {fields, fieldsDropdownFilter, optionAnd, optionOr,} from '../../utils/dictionary';
import Timepicker from '@vuepic/vue-datepicker';

const defaultRowPrototype: IFilterRow = {
    // First column - and / or
    // Not actual for rows[0]
    firstColumnVal: optionAnd.type,
    // Second column - field type
    secondColumnVal: fields['date'].type,
    // Third column - field type option
    thirdColumnOptions: fields['date'].options,
    thirdColumnVal: fields['date'].options[0].type,
    // FourthColumn - field text value
    fourthColumnVal: null,
}

export default defineComponent({
    name: 'DropdownFilter',
    components: {
        Timepicker,
        SaveFilterModal,
        DatePicker,
        VSelect,
    },
    inject: ['dataLoadingByFilter'],
    props: {
        filters: {
            type: Array,
            default: function () {
                return [defaultRowPrototype];
            },
        },
        defaultNumFilters: {
            type: Number,
            default: 0,
        },
    },
    data: function () {
        return {
            fields: fieldsDropdownFilter,
            rows: cloneDeep(this.filters?.length ? this.filters : [defaultRowPrototype]) as IFilterRow[],
            optionAnd,
            optionOr,
            filterData: {},
            isInvalidFilters: false,
            serverErrorMessage: null,

            durationVideo: null,
            timeStart: null,
            timeEnd: null,
            startTime: {
                hours: 0,
                minutes: 0,
                seconds: 0
            },
            intervalTimeStartPlaceholder: this.$t('time_picker.start'),
            intervalTimeEndPlaceholder: this.$t('time_picker.end'),
            durationTimePlaceholder: this.$t('time_picker.video_duration'),

            totalSecondsDuration: null,
            totalSecondsStartDuration: null,
            totalSecondsEndDuration: null,
            isValidDuration: false,
            timePickerDataInterval: [],
            timePickerData: null
        }
    },

    computed: {
        ...mapGetters('auth', {
            isGrantedPolicy: 'isGrantedPolicy',
        }),
        ...mapGetters('filter', {
            personsIsLoading: 'isLoading',
            recognizedPersons: 'recognizedPersons',
            videoModerators: 'videoModerators',
            personsCount: 'personsCount',
        }),
        ...mapGetters('settings', {
            isMlEnabled: 'isMlEnabled'
        }),

        /* Getters from Vuex */
        mobileVersion() {
            const innerWidth = window.innerWidth && document.documentElement.clientWidth ?
                Math.min(window.innerWidth, document.documentElement.clientWidth) :
                window.innerWidth ||
                document.documentElement.clientWidth ||
                document.getElementsByTagName('body')[0].clientWidth;

            return innerWidth < 755;
        },
    },
    watch: {
        filters() {
            this.rows = cloneDeep(this.filters?.length ? this.filters : [defaultRowPrototype]) as IFilterRow[];
        },
        currentPage: function () {
            this.getFilterData();
        },
        isMlEnabled() {
            if (!this.isMlEnabled.value) {
                delete this.fields.staff;
            }
        },
        timeStart() {
            this.totalSecondsStartDuration = this.timeStart?.hours * 60  + this.timeStart?.minutes + this.timeStart?.seconds
            this.isValidDuration = this.totalSecondsStartDuration < this.totalSecondsEndDuration

            this.rows.forEach(row => {
                if (row.thirdColumnVal === 11) {
                    this.timePickerDataInterval[0] = this.formatTime(this.timeStart)
                    row.fourthColumnVal = this.timePickerDataInterval
                }
            })
            this.validate()
        },
        timeEnd() {
            this.totalSecondsEndDuration = this.timeEnd?.hours * 60  + this.timeEnd?.minutes + this.timeEnd?.seconds
            this.isValidDuration = this.totalSecondsStartDuration < this.totalSecondsEndDuration

            this.rows.forEach(row => {
                if (row.thirdColumnVal === 11) {
                    this.timePickerDataInterval[1] = this.formatTime(this.timeEnd)
                    row.fourthColumnVal = this.timePickerDataInterval
                }
            })
            this.validate()
        },
        durationVideo() {
            // this.totalSecondsDuration = this.durationVideo?.hours * 60  + this.durationVideo?.minutes + this.durationVideo?.seconds
            // this.isValidDuration = this.totalSecondsDuration > 0

            this.rows.forEach(row => {
                if (row.thirdColumnVal === 12) {
                    this.timePickerData = this.formatTime(this.durationVideo)
                    row.fourthColumnVal = this.timePickerData
                }
            })
            this.validate()
        }
    },
    async mounted() {
        await this.getFilterData();

        if (this.isGrantedPolicy('Recorder.Participant')) {
            await this.getRecognizedPersons();
        }
        await this.getVideoModerators()

        this.validate();
    },

    methods: {
        ...mapActions('persons', {
            personsGetList: 'getList',
        }),
        ...mapActions('filter', {
            getRecognizedPersons: 'getRecognizedPersons',
            getVideoModerators: 'getVideoModerators'
        }),
        ...mapMutations('paginationVideos', {
            setCurrentPage: 'setCurrentPage'
        }),

        async addRow() {
            this.rows.push(cloneDeep(defaultRowPrototype));
            this.validate();
        },

        async getFilterData() {
            this.personsGetList({searchStr: ''});
        },

        async deleteRow(index) {
            // VueBootstrap bug fix: if user click on the button of the last element of the this.rows,
            // then dropdown-filter will close. We don't need this behavior.
            setTimeout((scope) => {
                scope.rows.splice(index, 1);
                this.validate();
            }, 0, this);
        },

        async openSaveFilterModal() {
            this.$refs.saveFilterModal.show();
        },

        async save(name: string) {
            this.serverErrorMessage = null;

            const items: IFilterItemDto[] = makeVideoFilterItems(this.rows).map(item => ({
                ...item,
                specValue: JSON.stringify(item.specValue as unknown),
            }));

            const filterDto: IFilterSetDto = {
                title: name,
                targetEntityId: 'd0f5762e-4e50-4d6a-8edb-da67364f0614',
                isDefault: false,
                isStatic: false,
                isPublic: false,
                isHidden: false,
                items,
                // ownerId: '',
            } as any;

            try {
                const savedFilterDto = await api.filterSetServiceProxy.savePost('1.0', filterDto as any).toPromise();
                await this.apply(undefined, savedFilterDto);
                this.$refs.saveFilterModal.hide();
            } catch (e) {
                this.serverErrorMessage = e.error?.message || 'Во время выполнения запроса произошла ошибка.';
            }
        },

        async apply(event, filterDto: IFilterSetDto | undefined) {
            this.$emit('setFilters', this.rows);
            this.$emit('callback');
            this.$emit('setDefaultNumFilters', this.rows.length);

            if (filterDto) {
                this.$emit('saveFilterDto', filterDto);
            }

            // this.$refs.dropdown.hide(true);
            await this.getFilterData();
            this.setCurrentPage(1)
            this.dataLoadingByFilter()
        },

        async clear() {
            this.rows = [cloneDeep(defaultRowPrototype)];
            this.$emit('setDefaultNumFilters', 0);
            this.$emit('setFilters', []);
            this.$emit('callback');
            this.validate();
        },

        // Set options by field key
        async changeSecondColumnVal(row) {
            const key = row.secondColumnVal;
            row.thirdColumnOptions = fields[key].options;
            row.thirdColumnVal = fields[key].options[0].type;
            row.fourthColumnVal = null;
            this.durationVideo = null;
            this.timeStart = null;
            this.timeEnd = null;
            this.validate();
        },

        // eslint-disable-next-line no-unused-vars
        changeThirdColumnVal(row) {
            this.durationVideo = null;
            this.timeStart = null;
            this.timeEnd = null;
            if (row.thirdColumnVal === 12 || row.thirdColumnVal === 8) {
                row.fourthColumnVal = null
            }
            this.validate();
        },

        // eslint-disable-next-line no-unused-vars
        changeFourthColumnVal(row) {
            this.validate();
        },

        validate() {
            this.isInvalidFilters = this.rows?.some(row => {
                const {secondColumnVal: type, thirdColumnVal: operator, fourthColumnVal: value} = row;

                if (type !== 'confidentially' && type !== 'deletionProtection') {
                    if (type !== 'duration') {
                        return type === null || operator === null || value === null || value === '';
                    }

                    if (operator === 12) {
                        return type === null || operator === null || value === null || value === '';
                    }

                    return !this.isValidDuration || this.totalSecondsStartDuration === null;
                }
            });
        },

        async setOperation(item) {
            this.rows.forEach((el) => {
                el.firstColumnVal = item;
            });
        },

        getPersonDisplayLabel(person) {
            return getPersonDisplayLabel(person);
        },

        getModeratorDisplayLabel(moderator) {
            return moderator.fullName
        },

        handlePicker() {
            if (this.$i18n.locale === 'ru') {
                const btnCancel = document.querySelector('.dp__action_cancel');
                const btnSelect = document.querySelector('.dp__action_select');
                btnCancel.textContent = 'Отмена';
                btnSelect.textContent = 'Выбрать';
            }
        },

        formatTime(time) {
            const padZero = (num) => String(num).padStart(2, '0');
            return `${padZero(time?.hours)}:${padZero(time?.minutes)}:${padZero(time?.seconds)}`;
        }
    },

});
