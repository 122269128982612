import {i18n} from '@app/i18n';
import {api} from '@app/system/api/api-services';
import {axiosVinteoRecordApiWithCredentials} from '@app/system/api/old';
import {
    IVideoDto,
    IVideoEventDto,
    IVideoGrantAccessInput,
    PermissionGrantInfoDto,
    SpecificationParameters,
    VideoEventType,
} from '@app/system/api/service-proxies/service-proxies';
import {getTime, parseTimeString} from '@app/utils/helpers';

export interface IVideoModel extends IVideoDto {
    texts: IVideoEventDto[];
    participants: any[],
    permissions: PermissionGrantInfoDto[];
    durationText: string;
    duration: {
        totalSeconds: number;
    } | any;
}

export function toVideo(video: IVideoDto): IVideoModel {
    const model: IVideoModel = {
        texts: [],
        participants: (video as any).participants || [],
        permissions: (video as any).permissions || [],
        ...video,
        durationText: video.duration as any,
        duration: parseTimeString(video.duration as any),
    };

    if (model.events) {
        model.events = model.events.sort((a, b) => {
            const aStart = parseInt((a.startTime as any).replace(/:/g, ''));
            const bStart = parseInt((b.startTime as any).replace(/:/g, ''));
            if (aStart > bStart) {
                return 1;
            } else if (aStart < bStart) {
                return -1;
            }
            return 0;
        });

        model.texts = model.events.filter((event: IVideoEventDto) => {
            return event.eventType === VideoEventType._0 && event.text;
        });

        model.texts.forEach(text => {
            model.participants.forEach(participant => {
                if (text.participantId === participant.id) {
                    text.participant = participant;
                }
            });
        });

        model.events.forEach((event: IVideoEventDto) => {
            model.participants.forEach(participant => {
                if (event.participantId === participant.id) {
                    event.participant = participant;
                }
            });
        });

        // resp.events.forEach((event: IVideoEventDto) => {
        //     if (
        //         event.participant
        //         && !resp.participants.find(item => item.id === event.participant.id)
        //     ) {
        //         resp.participants.push(event.participant);
        //     }
        // });
    }

    // console.log('toVideo', video, model.permissions);

    return model;
}

function initialState() {
    return {
        video: null,
        player: false,
        error: null,
        isLoading: false,
        isServerError: false,
        isParticipantName: false,
        accessUrls: [],
        accessPersons: [],
        accessUrlsTotalCount: 0,
        accessPersonsTotalCount: 0,

        searchStr: '',
        shareId: null,

        videosSelected: null,
        videos: [],
        fullTextSearchVideosResult: [],
    };
}

const getters = {
    video(state) {
        return state.video;
    },
    error(state) {
        return state.error;
    },
    isParticipantName(state) {
        return state.isParticipantName;
    },
    isLoading(state) {
        return state.isLoading;
    },
    isServerError(state) {
        return state.isServerError;
    },
    videosSelected(state) {
        return state.videosSelected;
    },
    videos(state) {
        return state.videos;
    },
    fullTextSearchVideosResult(state) {
        return state.fullTextSearchVideosResult;
    },
    accessUrls(state) {
        return state.accessUrls
    },
    accessPersons(state) {
        return state.accessPersons
    }
};

const actions = {

    changeVideoPublicity({commit}, {videoId, publicity}) {
        const payload = {
            'access': publicity,
        };
        const urlSegment = `videos/${videoId}/access`;
        return new Promise((resolve, reject) => {
            axiosVinteoRecordApiWithCredentials
                .patch(urlSegment, payload)
                .then((resp) => {
                    commit('setPublicity', resp.data.access);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    addVideoAccessForUser({commit}, {videoId, userId}) {
        const payload = {
            'user_id': userId,
            'video_id': videoId,
        };
        const urlSegment = `videos/permissions/grant`;
        return new Promise((resolve, reject) => {
            axiosVinteoRecordApiWithCredentials
                .patch(urlSegment, payload)
                .then((resp) => {
                    commit('setPermissions', resp.data.usersAccessGranted);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    deleteVideoAccessForUser({commit}, {videoId, userId}) {
        const payload = {
            'user_id': userId,
            'video_id': videoId,
        };
        const urlSegment = `videos/permissions/remove`;
        return new Promise((resolve, reject) => {
            axiosVinteoRecordApiWithCredentials
                .patch(urlSegment, payload)
                .then((resp) => {
                    commit('setPermissions', resp.data.usersAccessGranted);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getVideo({commit}, payload: {id: string, accessTicketId?: string}) {
        const {id, accessTicketId} = payload;

        commit('setError', null);
        commit('setVideo', null);

        if (accessTicketId) {
            window['$accessTicketId'] = accessTicketId;
        }

        return Promise.all([
            api.videosServiceProxy.detailedInfo(id, '1.0').toPromise(),
            api.objectAccessServiceProxy.grantedPermissionsForEntity(
                'D0F5762E-4E50-4D6A-8EDB-DA67364F0614',
                id,
                undefined,
                '1.0',
            ).toPromise(),
        ]).then(([video, permissions]) => {
            (video as any).participants = Object.values(video.participantMap);
            (video as any).permissions = permissions;
            const videoModel = toVideo(video) as unknown as IVideoModel;
            commit('setVideo', videoModel);
        })
            .catch((err) => {
                commit('setError', err);
            });
    },

    playerOn({commit}) {
        commit('setPlayer', true);
    },

    playerOff({commit}) {
        commit('setPlayer', false);
    },

    getTime(value) {
        return getTime(value);
    },

    deleteVideo({commit}, id) {
        return api.videosServiceProxy.delete(id, '1.0').toPromise()
            .then(() => {
            });
    },

    isParticipantFullName({commit}) {
        return api.settingsServiceProxy.app('1.0').toPromise()
            .then(data => {
                commit('setParticipantName', data.participantNameIsVisible);
            });
    },

    getAccessesUrls({commit, state}, payload) {
        commit('setLoading');

        // const pageNumber = payload.currentPage;
        const pageNumber = 1;
        const pageSize = payload.perPage;
        const countTotal = true;
        const searchStr = state.searchStr;

        const filterByVideo = new SpecificationParameters({
            specId: 'BFAF0DED-90FD-48F7-ABC3-4A7000893FD0',
            parameters: {value: state.video.id},
        });
        const specTwo = new SpecificationParameters({
            specId: 'E434BEE9-A607-436D-894E-79BCD5783845',
            parameters: {value: 1},
        });
        const specThree = new SpecificationParameters({
            specId: 'E7DCFBF3-B1A3-4F14-9100-6AF21D638C78',
            parameters: {value: 10},
        });
        const filterByAccessesUrls = new SpecificationParameters({
            specId: '527AA815-2140-4BD3-957C-3C503AC9F365',
            parameters: {value: `%${searchStr}%`},
        });
        const filterByDate = new SpecificationParameters({
            specId: '5D509470-3AAA-4959-8555-A0AA6F74BBA1',
            parameters: {value: new Date().toISOString()},
        });

        const specifications = [filterByVideo, specTwo, specThree, filterByDate, filterByAccessesUrls];

        return api.accessTicketServiceProxy.page(pageNumber, pageSize, countTotal, '1.0', specifications).toPromise()
            .then(resp => {
                commit('setLoaded');
                commit('setAccessUrls', resp.data);
                commit('setAccessUrlsTotalCount', resp.totalCount);
            })
            .catch(err => {
                commit('setLoaded');
                commit('setServerError', err);
            });
    },

    getAccessesPersons({commit, state}, payload) {
        commit('setLoading');

        const pageNumber = payload.currentPage;
        const pageSize = payload.perPage;
        const countTotal = true;
        const searchStr = state.searchStr;

        const specOne = new SpecificationParameters({
            specId: 'FFF35F74-FE99-4B11-929B-C5D7164436D6',
            parameters: {value: state.video.id},
        });
        const specTwo = new SpecificationParameters({
            // specId: '02F492AD-4F74-40F3-BECC-C0961595AEF5',
            specId: 'B8055BD6-2E1C-4393-9BED-EFDB2AC325FC',
            parameters: {value: `%${searchStr}%`},
        });

        const specifications = [specOne, specTwo];

        return api.objectAccessServiceProxy.page(pageNumber, pageSize, countTotal, '1.0', specifications)
            .toPromise()
            .then(resp => {
                commit('setLoaded');
                commit('setAccessPersons', resp.data);
                commit('setAccessPersonsTotalCount', resp.data.length);
            })
            .catch(err => {
                commit('setLoaded');
                commit('setServerError', err);
            });
    },

    deleteAccessUrls({commit, state}, payload) {
        commit('setLoading');

        return api.accessTicketServiceProxy.revokeById(payload, '1.0').toPromise()
            .then()
            .catch(err => {
                commit('setServerError', err);
            });
    },

    deleteAccessPerson({commit, state}, userId) {
        commit('setLoading');

        return api.videosServiceProxy.revokeAccess(state.video.id, userId, '1.0').toPromise()
            .then()
            .catch(err => {
                commit('setServerError', err);
            });
    },

    createAccess({commit, state}, payload) {
        const newAccess: IVideoGrantAccessInput = {
            videoId: state.video.id,
            userId: null,
            expiresAfter: payload.term,
            targetUrl: payload.targetUrl,
            description: payload.name,
            forceNewTicket: payload.indefinitely,
        };

        return api.videosServiceProxy.grantAccess('1.0', newAccess as any).toPromise()
            .then((resp) => {
                commit('setShareId', resp.id);
            })
            .catch(err => {
                commit('setServerError', err);
            });
    },

    grantPersistentAccess({commit, state}, payload) {
        return api.videosServiceProxy.grantPersistentAccess('1.0', payload as any).toPromise()
            .then((resp) => {
                commit('setShareId', resp.id);
            })
            .catch(err => {
                commit('setServerError', err);
            });
    },

    revokeAccess({commit, state}, payload) {

        const videoId = payload.videoId
        const userId = payload.userId
        return api.videosServiceProxy.revokeAccess(videoId, userId, '1.0').toPromise()
            .then((resp) => {})
            .catch(err => {
                commit('setServerError', err);
            });
    },

    // Отправка видео для повторного распознования в ML
    reprocess({commit}, videoId) {
        return api.videosServiceProxy.reprocess(videoId,'1.0').toPromise()
            .then((resp) => {})
            .catch(err => {
                commit('setServerError', err);
            });
    },

    // Обрезка видео

    videoTrim({commit}, payload) {
        console.log('payload', payload);
        return api.videosServiceProxy.edit('1.0', payload).toPromise()
            .then((res) => {
                console.log(res);
            });
    },
};

const mutations = {
    setPublicity(state, value) {
        state.video.access = value;
    },
    setPermissions(state, value) {
        state.video.usersAccessGranted = value;
    },
    setVideo(state, value) {
        state.video = value;
    },
    setPlayer(state, value) {
        state.player = value;
    },
    setError(state, value) {
        state.error = value;
    },
    setParticipantName(state, value) {
        state.isParticipantName = value;
    },
    setAccessUrls(state, value) {
        state.accessUrls = value;
    },
    setAccessPersons(state, value) {
        state.accessPersons = value;
    },
    setAccessPersonsTotalCount(state, value) {
        state.accessPersonsTotalCount = value;
    },
    setAccessUrlsTotalCount(state, value) {
        state.accessUrlsTotalCount = value;
    },
    setSearchStr(state, searchStr) {
        state.searchStr = searchStr;
    },
    resetSearchStr(state) {
        state.searchStr = '';
    },
    setLoading(state) {
        state.isLoading = true;
    },
    setLoaded(state) {
        state.isLoading = false;
    },
    setServerError(state, err) {
        state.isServerError = true;
        if (err.response && err.response.data && err.response.data.error) {
            state.serverErrorMessage = err.response.data.error;
        } else {
            state.serverErrorMessage = i18n.global.t('unknown_error');
        }
    },
    setShareId(state, value) {
        state.shareId = value;
    },
    setVideosSelected(state, videos) {
        state.videosSelected = videos;
    },
    setVideos(state, videos) {
        state.videos = videos;
    },
    setFullTextSearchVideos(state, videos) {
        state.fullTextSearchVideosResult = videos;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
