import {api} from '@app/system/api/api-services';
import {BrandingSettingsDto} from '@app/system/api/service-proxies/service-proxies';

function initialState() {
    return {
        notificationSettings: null,
    };
}

const getters = {
    notificationSettings(state) {
        return state.notificationSettings
    },
};

const actions = {
    async getNotificationSettings({commit}) {
        try {
            const resp = await api.settingsServiceProxy.notifications('1.0').toPromise();
            commit('notificationSettings', resp);
        } catch (error) {
            console.error(error);
        }
    },

    async saveNotificationSettings({commit}, settings) {
        try {
            await api.notificationsServiceProxy.savePost('1.0', settings).toPromise();

        } catch (error) {
            console.error(error);
        }
    },
};

const mutations = {
    setNotificationSettings(state, settings) {
        state.notificationSettings = settings
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
