import {createStore} from 'vuex';

import paginationVideos from '@app/modules/video/video-pagination.store';

import audit from '../modules/audit/audit.store';
import securityLogs from '../modules/audit/security-logs.store';
import auth from '../modules/auth/auth.store';
import filter from '../modules/filter/filter.store';
import persons from '../modules/person/person.store';
import brandingSettings from '../modules/settings/branding-settings.store';
import notificationSettings from '../modules/settings/notification-settings.store';
import settings from '../modules/settings/settings.store';
import roles from '../modules/user/role.store';
import users from '../modules/user/user.store';
import video from '../modules/video/video.store';
import route from './route.store';

const store = createStore({
    modules: {
        auth,
        users,
        persons,
        filter,
        video,
        paginationVideos,
        roles,
        route,
        audit,
        securityLogs,
        settings,
        brandingSettings,
        notificationSettings,
    },
});

export default store;
