
import {useVuelidate} from '@vuelidate/core';
import {helpers, minLength, required} from '@vuelidate/validators'
import {defineComponent} from 'vue';
import {mapActions, mapGetters, mapMutations} from 'vuex';

import {IChangeAccountInput} from '@app/system/api/service-proxies/service-proxies';
import {i18n} from '@app/i18n';
import {deleteModalBackdrop} from '@app/utils/helpers';
import {generateRandomString} from '@app/utils/random';
import {cyrillicDisable, validationPassword} from '@app/utils/validators';
import Modal from "@app/components/ui-kit/molecules/Modal.vue";
import UiErrorSnackbar from "@app/components/ui-kit/molecules/ErrorSnackbar.vue";

const firstNameRegex = /^[а-яА-ЯёЁa-zA-Z]+$/;
const firstNameRegexValidatorFunc = helpers.regex(firstNameRegex);
const validateOtherName = /^[а-яА-ЯёЁa-zA-Z\\-]+$/;
const otherNameRegexValidatorFunc = helpers.regex(validateOtherName);

export default defineComponent({
    name: 'EditUserModal',
    components: {
        UiErrorSnackbar,
        Modal,
    },

    inject: ['openEditPersonModal'],

    props: {
        selectedUser: {
            type: Object,
            default: Boolean,
        }
    },

    setup() {
        return {v$: useVuelidate()}
    },

    data() {
        return {
            id: 'edit-user-modal',
            pageTitle: this.$t('modal_titles.user_editing'),
            sended: false,
            modalShow: false,
            form: {
                lastName: '',
                firstName: '',
                middleName: '',
                email: '',
                password: '',
                grantedAccessGroups: [],
            },
            locale: 'ru',
            isValidFirstName: true,
            isValidLastName: true,
            isValidMiddleName: true,
        }
    },

    computed: {
        ...mapGetters('auth', {
            isGrantedPolicy: 'isGrantedPolicy',
            userEmail: 'userEmail',
        }),

        ...mapGetters('users', {
            isLoading: 'isLoading',
            isServerError: 'isServerError',
            serverErrorMessage: 'serverErrorMessage',
        }),

        ...mapGetters('roles', {
            roles: 'roles',
            allRoles: 'allRoles',
            rolesCount: 'rolesCount',
        }),

        ...mapGetters('persons', {
            person: 'person'
        }),

        title() {
            return this.pageTitle;
        },

        validatePassword() {
            return validationPassword(this.form.password, this.locale)
        },

        isSubmitAvailable() {
            if (!this.isGrantedPolicy('Recorder.UserAccount.Update')) {
                return false;
            }

            const isFirstNameValid = this.form.firstName?.length >= 2 && this.isValidFirstName;
            const isLastNameValid = this.form.lastName?.length >= 2 && this.isValidLastName;
            const isMiddleNameValid = (!this.form.middleName?.length || this.form.middleName?.length >= 2) && this.isValidMiddleName
            const isUserExternal = this.selectedUser?.isExternal;

            return !((isFirstNameValid && isLastNameValid && isMiddleNameValid) || isUserExternal)
        },
    },

    validations() {
        return {
            form: {
                lastName: {required, minLength: minLength(2), otherNameRegexValidatorFunc},
                firstName: {required, minLength: minLength(2), firstNameRegexValidatorFunc},
                middleName: {
                    required,
                    ...(this.selectedUser?.isExternal ? {} : { minLength: minLength(2) }),
                    otherNameRegexValidatorFunc
                },
                password: {
                    required, minLength: minLength(8)
                },
            },
        }
    },

    watch: {
        '$route'(to) {
            deleteModalBackdrop(to)
        },
    },

    async mounted() {
        this.locale = i18n.global.locale;
        await this.getRolesAllList();
        this.setPerson(null)
    },

    methods: {
        ...mapActions('users', {
            getInstance: 'getInstance',
            getGrantedAccessGroups: 'getGrantedAccessGroups',
            saveUser: 'save',
        }),

        ...mapActions('roles', {
            getRolesAllList: 'getAllList',
            getRole: 'getInstance',
            createRole: 'add',
            saveRole: 'save',
            deleteRole: 'delete',
        }),

        ...mapActions('persons', {
            getUserData: 'getInstance',
            getPersonByOwnerId: 'getPersonByOwnerId'
        }),

        ...mapMutations('persons', {
            setPerson: 'setPerson'
        }),

        handleInputFirstName() {
            this.isValidFirstName = firstNameRegex.test(this.form.firstName) && this.form.firstName.length
        },

        handleInputOtherName(e: InputEvent) {
            const targetInput = e.target as HTMLInputElement;

            if (targetInput.id === 'input-last-name') {
                this.isValidFirstName = validateOtherName.test(this.form.lastName) && this.form.lastName.length
            }
            if (targetInput.id === 'input-middle-name') {
                this.isValidFirstName = validateOtherName.test(this.form.middleName) && this.form.middleName.length
            }
        },

        noCyrillicChar(e: InputEvent) {
            return cyrillicDisable(e)
        },

        show() {
            this.modalShow = true;
            this.v$.$reset()
        },

        hide() {
            this.modalShow = false;
        },

        async submitForm() {
            const changeAccountInput: IChangeAccountInput = {
                id: this.selectedUser.id,
                lastName: this.form.lastName || '',
                firstName: this.form.firstName || '',
                middleName: this.form.middleName || '',
                emailAddress: this.form.email,
                password: this.form.password || null,
                phone: '',
                nickname: undefined,
                birthDate: this.form.birthDate || undefined,
                userName: this.form.email,
                isOtp: false,
                isForceActivation: true,
                grantedAccessGroups: this.form.grantedAccessGroups?.map(item => item.id) || [],
                extraProperties: undefined,
            };

            this.saveUser(changeAccountInput)
                .then(() => {
                    // this.pageTitle = this.form.email;
                    this.$emit('userCallback');
                    this.hide();
                }).catch((e) => {
                console.log(e);
            });
        },

        async resetForm() {
            this.form = {
                lastName: '',
                firstName: '',
                middleName: '',
                email: '',
                password: '',
                grantedAccessGroups: [],
            };

            try {
                const user = await this.getInstance(this.selectedUser.id);
                const groups = await this.getGrantedAccessGroups(this.selectedUser.id);

                this.disabled = user.lastName === 'admin' || user.lastName === 'unregistered';

                if (user.middleName) {
                    user.middleName = user.middleName.replace(/\./g, '');
                }

                this.form = {
                    lastName: user.lastName,
                    firstName: user.firstName,
                    middleName: user.middleName,
                    email: user.email,
                    password: '',
                    birthDate: user.birthDate,
                    grantedAccessGroups: this.allRoles.filter(role => groups.includes(role.id)) || [],
                };
            } catch (e) {
                console.log(e);
            }
        },

        async generatePassword() {
            this.form.password = generateRandomString(8);
        },

        async addAccessGroups(accessGranted: string) {
            console.log('accessGranted', accessGranted);
        },

        async resetModal() {
            await this.resetForm();
        },

        async closeModal() {
            this.hide();
            this.setPerson(null)
        },

        async openCardPerson() {
            console.log('person ===>', this.person)

            if (this.person) {
                this.hide();
                this.openEditPersonModal(this.person);
            } else {
                try {
                    const resp = await this.getPersonByOwnerId(this.selectedUser.id);

                    if (resp === null) {
                        this.$refs.errorSnackbar.show({
                            message: i18n.global.t('errors.no_participant_found')
                        })
                        return;
                    }
                    this.hide();
                    this.openEditPersonModal(resp);
                } catch (error) {
                    console.error(error);
                }
            }
        }
    },
});
