import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-90c5fb58"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "person-modal" }
const _hoisted_2 = {
  key: 0,
  class: "form"
}
const _hoisted_3 = { class: "form__avatar-block d-flex flex-column" }
const _hoisted_4 = { class: "form__avatar" }
const _hoisted_5 = { class: "d-flex flex-column" }
const _hoisted_6 = {
  key: 0,
  class: "d-flex flex-column"
}
const _hoisted_7 = { class: "form__owner-link mb-2" }
const _hoisted_8 = { class: "form__fields" }
const _hoisted_9 = { style: {"color":"red"} }
const _hoisted_10 = {
  key: 0,
  class: "person-number"
}
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { key: 0 }
const _hoisted_13 = {
  key: 0,
  class: "mt-1 form__invalid-message"
}
const _hoisted_14 = {
  key: 1,
  class: "content-loader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_file = _resolveComponent("b-form-file")!
  const _component_b_form_group = _resolveComponent("b-form-group")!
  const _component_vue3_simple_typeahead = _resolveComponent("vue3-simple-typeahead")!
  const _component_b_form_textarea = _resolveComponent("b-form-textarea")!
  const _component_b_form = _resolveComponent("b-form")!
  const _component_Loader = _resolveComponent("Loader")!
  const _component_modal = _resolveComponent("modal")!

  return (_openBlock(), _createBlock(_component_modal, {
    id: _ctx.id,
    ref: "modal",
    "modal-show": _ctx.modalShow,
    title: _ctx.$t('modal_titles.employee_editing'),
    size: "lg",
    "ok-title": _ctx.$t('buttons.save'),
    "cancel-title": _ctx.$t('buttons.cancel'),
    "ok-disabled": !_ctx.isSubmitAvailable,
    onShow: _ctx.resetModal,
    onHidden: _ctx.closeModal,
    onOk: _ctx.submitForm
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (!_ctx.form.isLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", {
                    class: "form__avatar-card border overflow-hidden",
                    style: _normalizeStyle({
                                backgroundImage: 'url(' + _ctx.getAvatarImgOrPlaceholder + ')'
                            })
                  }, null, 4)
                ]),
                _createElementVNode("div", _hoisted_5, [
                  (_ctx.selectedPerson?.primaryEmail?.data || this.emailParticipant)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createTextVNode(" Email пользователя "),
                        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.selectedPerson?.primaryEmail?.data || this.emailParticipant), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.selectedPerson?.ownerId || _ctx.ownerId)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 1,
                        class: "form__owner-link",
                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openCardUser && _ctx.openCardUser(...args)))
                      }, " Открыть карточку пользователя "))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_b_form, {
                  onSubmit: _ctx.submitForm,
                  onReset: _ctx.resetForm
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.errorText), 1),
                    _createVNode(_component_b_form_group, {
                      id: "input-group-avatar",
                      "label-for": "input-avatar"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_form_file, {
                          id: "input-avatar",
                          modelValue: _ctx.form.avatarFile,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.avatarFile) = $event)),
                          accept: ".jpg",
                          "browse-text": _ctx.$t('buttons_common.select_file'),
                          "drop-placeholder": _ctx.$t('placeholders_common.choose_file'),
                          onChange: _ctx.setAvatarImage
                        }, null, 8, ["modelValue", "browse-text", "drop-placeholder", "onChange"])
                      ]),
                      _: 1
                    }),
                    (_ctx.form.docNumber)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t('modal_titles.participant_number')) + ": " + _toDisplayString(_ctx.form.docNumber), 1))
                      : _createCommentVNode("", true),
                    _createVNode(_component_b_form_group, {
                      id: "input-group-fullname",
                      "label-for": "input-fullname"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: _normalizeClass(["vue3-simple-typeahead", {'invalid' : _ctx.v$.form.fullName.$error, 'is-valid' : _ctx.v$.form.fullName.$dirty && !_ctx.v$.form.fullName.$error}])
                        }, [
                          _createVNode(_component_vue3_simple_typeahead, {
                            id: "input-fullname",
                            ref: "typeahead",
                            modelValue: _ctx.v$.form.fullName.$model,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.v$.form.fullName.$model) = $event)),
                            modelModifiers: { trim: true },
                            placeholder: _ctx.$t('titles.fullname'),
                            items: _ctx.personItems,
                            "default-item": _ctx.form.fullName,
                            "min-input-length": 1,
                            "item-projection": _ctx.itemProjection,
                            class: _normalizeClass({
                                        'invalid': _ctx.v$.form.fullName.$error,
                                        'is-valid': _ctx.v$.form.fullName.$dirty && !_ctx.v$.form.fullName.$error
                                    }),
                            onSelectItem: _ctx.selectItemEventHandler,
                            onOnInput: _ctx.onInputEventHandler
                          }, {
                            "list-item-text": _withCtx((slot) => [
                              _createElementVNode("span", {
                                innerHTML: slot.boldMatchText(slot.itemProjection(slot.item))
                              }, null, 8, _hoisted_11),
                              (slot.item?.primaryEmail?.data || this.emailParticipant)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_12, "  (" + _toDisplayString(slot.item?.primaryEmail?.data || this.emailParticipant) + ") ", 1))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }, 8, ["modelValue", "placeholder", "items", "default-item", "item-projection", "class", "onSelectItem", "onOnInput"])
                        ], 2),
                        (_ctx.v$.form.fullName.$error && !_ctx.isValidName)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.$t('modal_titles.firstAndLastNameRequired')), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_b_form_group, {
                      id: "input-group-personal-data",
                      "label-for": "input-personal-data"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_form_textarea, {
                          id: "input-personal-data",
                          modelValue: _ctx.form.personalData,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.personalData) = $event)),
                          placeholder: _ctx.$t('titles.personal_data'),
                          rows: "6",
                          type: "text",
                          disabled: _ctx.isLoading
                        }, null, 8, ["modelValue", "placeholder", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["onSubmit", "onReset"])
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _createVNode(_component_Loader)
            ]))
      ])
    ]),
    _: 1
  }, 8, ["id", "modal-show", "title", "ok-title", "cancel-title", "ok-disabled", "onShow", "onHidden", "onOk"]))
}