import {HttpClient, HttpXhrBackend} from '@angular/common/http';

import {appSettings} from '@app/config';
import {
    AccessGroupServiceProxy,
    AccountServiceProxy,
    EmployeeServiceProxy,
    FilterSetServiceProxy,
    IdentityServiceProxy,
    ParticipantServiceProxy,
    PersonServiceProxy,
    RolesServiceProxy,
    UsersServiceProxy,
    VideoServiceProxy,
    ApplicationConfigurationServiceProxy,
    VideoEventServiceProxy,
    SettingsServiceProxy,
    TimeZonesServiceProxy,
    AccessTicketServiceProxy,
    ObjectAccessServiceProxy,
    RoleServiceProxy,
    VinteoServiceProxy,
    VideoProcessingServiceProxy,
    SecurityLogServiceProxy,
    AuditLogServiceProxy,
    VideoModeratorServiceProxy,
    ManualUploadServiceProxy,
    AppServiceProxy,
    FileServiceProxy,
    BrandingServiceProxy,
    NotificationsServiceProxy,
} from '@app/system/api/service-proxies/service-proxies';

import {XhrFactoryReact} from './xhr-factory';

export let apiBaseUrl = appSettings.apiBaseUrl;

const xhrFactory = new XhrFactoryReact();
const httpHandler = new HttpXhrBackend(xhrFactory);
const httpClient = new HttpClient(httpHandler);

const getApiServices = () => {
    return {
        participantsServiceProxy: new ParticipantServiceProxy(httpClient, apiBaseUrl),
        usersServiceProxy: new UsersServiceProxy(httpClient, apiBaseUrl),
        employeeServiceProxy: new EmployeeServiceProxy(httpClient, apiBaseUrl),
        personServiceProxy: new PersonServiceProxy(httpClient, apiBaseUrl),
        videosServiceProxy: new VideoServiceProxy(httpClient, apiBaseUrl),
        accountServiceProxy: new AccountServiceProxy(httpClient, apiBaseUrl),
        accessGroupServiceProxy: new AccessGroupServiceProxy(httpClient, apiBaseUrl),
        identityServiceProxy: new IdentityServiceProxy(httpClient, apiBaseUrl),
        rolesServiceProxy: new RolesServiceProxy(httpClient, apiBaseUrl),
        filterSetServiceProxy: new FilterSetServiceProxy(httpClient, apiBaseUrl),
        applicationConfigurationServiceProxy: new ApplicationConfigurationServiceProxy(httpClient, apiBaseUrl),
        settingsServiceProxy: new SettingsServiceProxy(httpClient, apiBaseUrl),
        // serviceProxy: new ServiceProxy(httpClient, apiBaseUrl),
        // permissionsServiceProxy: new PermissionServiceProxy(httpClient, apiBaseUrl),
        // journalsServiceProxy: new JournalServiceProxy(httpClient, apiBaseUrl),
        videoEventServiceProxy: new VideoEventServiceProxy(httpClient, apiBaseUrl),
        timeZonesServiceProxy: new TimeZonesServiceProxy(httpClient, apiBaseUrl),
        accessTicketServiceProxy: new AccessTicketServiceProxy(httpClient, apiBaseUrl),
        objectAccessServiceProxy: new ObjectAccessServiceProxy(httpClient, apiBaseUrl),
        roleServiceProxy: new RoleServiceProxy(httpClient, apiBaseUrl),
        vinteoServiceProxy: new VinteoServiceProxy(httpClient, apiBaseUrl),
        videoProcessingServiceProxy: new VideoProcessingServiceProxy(httpClient, apiBaseUrl),
        securityLogServiceProxy: new SecurityLogServiceProxy(httpClient, apiBaseUrl),
        auditLogServiceProxy: new AuditLogServiceProxy(httpClient, apiBaseUrl),
        videoModeratorServiceProxy: new VideoModeratorServiceProxy(httpClient, apiBaseUrl),
        manualUploadServiceProxy: new ManualUploadServiceProxy(httpClient, apiBaseUrl),
        appServiceProxy: new AppServiceProxy(httpClient, apiBaseUrl),
        fileServiceProxy: new FileServiceProxy(httpClient, apiBaseUrl),
        brandingServiceProxy: new BrandingServiceProxy(httpClient, apiBaseUrl),
        notificationsServiceProxy: new NotificationsServiceProxy(httpClient, apiBaseUrl),

    };
};

export let api = getApiServices();

export const updateApiServices = (url: string) => {
    apiBaseUrl = url;
    api = getApiServices();
};

export const httpService = httpClient;
