import {ICreateAccountInput, IUserAccountDto, SpecificationParameters} from '@app/system/api/service-proxies/service-proxies';
import {i18n} from '@app/i18n';
import {api} from '@app/system/api/api-services';

function initialState() {
    return {
        isLoading: false,
        isServerError: false,
        serverErrorMessage: null,
        users: [],

        /* Pagination */
        usersCount: 0,
        perPage: 10,
        currentPage: 1,

        searchStr: ''
    };
}

const getters = {
    isLoading(state) {
        return state.isLoading;
    },

    isServerError(state) {
        return state.isServerError;
    },
    serverErrorMessage(state) {
        return state.serverErrorMessage;
    },

    users(state): IUserAccountDto[] {
        return state.users;
    },
    usersCount(state) {
        return state.usersCount;
    },
    perPage(state) {
        return state.perPage;
    },
    currentPage(state) {
        return state.currentPage;
    },
};

const actions = {
    getList({commit, state}) {

        commit('setLoading');

        const pageSize = state.perPage;
        const pageNumber = state.currentPage;

        const searchStr = state.searchStr;

        const filterForSearch = new SpecificationParameters({
            specId: '7F40AD87-E64D-41A9-AC14-BDD835BC5621',
            parameters: {value: `%${searchStr}%`},
        });

        const specifications = [];

        if (searchStr) {
            specifications.push(filterForSearch);
        }

        return api.accountServiceProxy.page(pageNumber, pageSize, true, '1.0', specifications)
            .toPromise()
            .then(resp => {
                commit('setLoaded');
                commit('setUsers', resp.data);
                commit('setUsersCount', resp.totalCount);
            })
            .catch(err => {
                console.log(err);
                commit('setLoaded');
                commit('setServerError', err);
            });
    },

    async getAllList() {
        const response = await api.accountServiceProxy.page(1, 1000, true, '1.0', [])
            .toPromise();
        return response;
    },

    getInstance({commit}, id) {
        commit('setLoading');

        return new Promise<IUserAccountDto>((resolve, reject) => {
            api.accountServiceProxy.get(id, '1.0').toPromise()
                .then(resp => {
                    // console.log('resp ===> getInstance', resp);
                        commit('setLoaded');
                        resolve(resp);
                    },
                )
                .catch((err) => {
                    commit('setLoaded');
                    commit('setServerError', err);
                    reject(err);
                });
        });
    },

    getGrantedAccessGroups({commit}, userId) {
        return new Promise((resolve, reject) => {
            api.accountServiceProxy.grantedAccessGroups(userId, '1.0').toPromise()
                .then(resp => {
                        resolve(resp);
                    },
                )
                .catch((err) => {
                    reject(err);
                });
        });
    },

    save({commit}, data) {
        commit('setLoading');

        return api.accountServiceProxy.update('1.0', {...data} as any)
            .toPromise()
            .then(resp => {
                commit('setLoaded');
            })
            .catch(err => {
                commit('setLoaded');
                commit('setServerError', err);
            });
    },

    delete({commit}, id) {
        commit('setLoading');

        return new Promise((resolve, reject) => {
            api.accountServiceProxy.delete(id, '1.0').toPromise()
                .then(resp => {
                        commit('setLoaded');
                        resolve(resp);
                    },
                )
                .catch((err) => {
                    commit('setLoaded');
                    commit('setServerError', err);
                    reject(err);
                });
        });
    },

    add({commit}, payload: ICreateAccountInput) {
        commit('setLoading');

        return api.accountServiceProxy.registerPost('1.0', payload as any)
            .toPromise()
            .then(resp => {
                commit('setLoaded');
            })
            .catch(err => {
                commit('setLoaded');
                commit('setServerError', err);
            });
    },
};

/* eslint-disable no-param-reassign */
const mutations = {
    setLoading(state) {
        state.isLoading = true;
        state.isServerError = false;
        state.serverErrorMessage = null;
    },
    setLoaded(state) {
        state.isLoading = false;
    },

    setServerError(state, err) {
        state.isServerError = true;
        if (err.response && err.response.data && err.response.data.error) {
            state.serverErrorMessage = err.response.data.error;
        } else {
            state.serverErrorMessage = i18n.global.t('unknown_error');
        }
    },

    setUsers(state, users) {
        /*
          Expect array of objects with fields:
          - email: <str>
          - id: <int>
          - first_name: <str>
          - last_name: <str>
        */
        users.forEach(user => {
            user.fullName = [user.lastName, user.firstName, user.middleName]
                .filter(namePart => namePart)
                .join(' ');
            if (user.email) {
                user.fullName += ` (${user.email})`;
            }
        });
        state.users = users
    },

    setUsersCount(state, count) {
        state.usersCount = count;
    },

    setPaginationInfo(state, paginationInfo) {
        state.perPage = paginationInfo.perPage;
        state.currentPage = paginationInfo.currentPage;
    },
    setSearchStr(state, searchStr) {
        state.searchStr = searchStr
    },
    resetSearchStr(state) {
        state.searchStr = ''
    },
    setCurrentPage(state, payload) {
        state.currentPage = payload;
    },
};
/* eslint-enable no-param-reassign */

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
