import VueVideoPlayer from '@videojs-player/vue';
import axios from 'axios';
import {BIconLink} from 'bootstrap-icons-vue';
import BootstrapVue3, {BToastPlugin} from 'bootstrap-vue-3';

import {createApp} from 'vue';
import DatePicker from 'vue-datepicker-next';
import Multiselect from 'vue-multiselect';
import VSelect from 'vue-select';
import VueClipboard from 'vue3-clipboard';
import SimpleTypeahead from 'vue3-simple-typeahead';
import {createVuetify} from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import {aliases, mdi} from 'vuetify/iconsets/mdi';

import BIcon from '@app/components/ui-kit/atoms/BIcon.vue';
import BTooltip from '@app/components/ui-kit/atoms/BTooltip.vue';
import {appSettings, setAppSettings} from '@app/config';
import {api, updateApiServices} from '@app/system/api/api-services';
import {getRouter} from '@app/system/router';

import App from './App.vue';
import {i18n} from './i18n';
import {getIdsrvAuth} from './modules/auth/services/idsrv-auth';
import store from './system/store';

import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css';
import 'vuetify/styles';
import 'video.js/dist/video-js.css';
import '@mdi/font/css/materialdesignicons.css';

async function start() {
    try {
        await loadSettings();
        const {idsrvAuth, authConfig} = await getAuth();

        const vuetify = createVuetify({
            components,
            directives,
            icons: {
                defaultSet: 'mdi',
                aliases,
                sets: {
                    mdi,
                },
            },
        });

        const app = createApp(App);

        // Hide in the Vue warnings console during development
        app.config.warnHandler = (msg, instance, trace) =>
            ![
                'built-in or reserved HTML elements as component id: component',
                '"class" is a reserved attribute and cannot be used as component prop',
                'Cannot find element: #__nuxt'
            ].some((warning) => msg.includes(warning))
        // && console.warn('[Vue warn]: '.concat(msg).concat(trace))

        app.use(i18n);
        app.use(getRouter());
        app.use(store);

        app.use(vuetify);

        app.use(BootstrapVue3);
        app.use(BToastPlugin);
        app.use(SimpleTypeahead);
        app.use(VueClipboard, {
            autoSetContainer: true,
            appendToBody: true,
        });
        app.use(VueVideoPlayer);

        app.component('BIconLink', BIconLink);
        app.component('VSelect', VSelect);
        app.component('DatePicker', DatePicker);
        app.component('BIcon', BIcon);
        app.component('BTooltip', BTooltip);
        app.component('VueMultiselect', Multiselect);

        app.config.globalProperties.$oidc = idsrvAuth;
        app.config.globalProperties.$authConfig = authConfig;

        app.mount('#app');

        return app;
    } catch (e) {
        console.log('AppSettings loading error!');
        console.error(e);
    }
}

async function loadSettings() {

    if (process.env.NODE_ENV === 'production') {
        const resp = await axios.get('/config/appsettings.json');
        setAppSettings(resp.data);
    } else {
        try {
            const data = require('./config/appsettings.development.json');
            setAppSettings(data);
        } catch (e) {
            console.error(e);
        }
    }
}

async function getAuth() {
    const idsrvAuth = getIdsrvAuth();
    const authResp = await idsrvAuth.startup();
    if (!authResp) {
        throw new Error('Startup was not ok');
    }

    window['$oidc'] = idsrvAuth;

    updateApiServices(appSettings.apiBaseUrl);

    const authConfig = idsrvAuth.accessToken
        ? await api.applicationConfigurationServiceProxy.authConfig('1.0').toPromise()
        : {};

    window['$authConfig'] = authConfig;

    return {
        idsrvAuth,
        authConfig,
    };
}

export const app = start();


