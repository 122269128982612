import {api} from '@app/system/api/api-services';
import {BrandingSettingsDto} from '@app/system/api/service-proxies/service-proxies';

function initialState() {
    return {
        brandingSettings: null,
        logo: null,
        favicon: null
    };
}

const getters = {
    brandingSettings(state) {
        // console.log('brandingSettings', state.brandingSettings);
        return state.brandingSettings
    },
    logo(state) {

        return state.logo
    },
    favicon(state) {
        return state.favicon
    }
};

const actions = {
    async getBrandingSettings({commit}) {
        try {
            const resp = await api.settingsServiceProxy.branding('1.0').toPromise();
            commit('setBrandingSettings', resp);
        } catch (error) {
            console.error(error);
        }
    },

    async saveBrandingSettings({commit}, settings) {
        try {
            await api.brandingServiceProxy.save('1.0', settings).toPromise();

        } catch (error) {
            console.error(error);
        }
    },

    async getBrandingFile({commit}, payload) {
        const fileType = payload.fileType
        const fileId = payload.fileId
        try {
            const resp = await api.fileServiceProxy.get(fileId,'1.0').toPromise();
            if (fileType === 'logo') {
                commit('setLogo', resp)
            } else {
                commit('setFavicon', resp)
            }
        } catch (error) {
            console.error(error);
        }
    },
};

const mutations = {
    setBrandingSettings(state, settings) {
        state.brandingSettings = settings
    },
    setLogo(state, logo) {
        state.logo = logo
    },
    setFavicon(state, favicon) {
        state.favicon= favicon
    }
};

/* eslint-enable no-param-reassign */
export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
