import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, withModifiers as _withModifiers, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c6df46c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-modal" }
const _hoisted_2 = { class: "mb-5" }
const _hoisted_3 = { class: "user-modal__email" }
const _hoisted_4 = { class: "form" }
const _hoisted_5 = { class: "form__fields" }
const _hoisted_6 = { class: "fio-wrap" }
const _hoisted_7 = {
  key: 0,
  class: "mt-1 invalid-message"
}
const _hoisted_8 = {
  key: 1,
  class: "mt-1 invalid-message"
}
const _hoisted_9 = {
  key: 0,
  class: "mt-1 invalid-message"
}
const _hoisted_10 = {
  key: 1,
  class: "mt-1 invalid-message"
}
const _hoisted_11 = {
  key: 0,
  class: "mt-1 invalid-message"
}
const _hoisted_12 = {
  key: 1,
  class: "mt-1 invalid-message"
}
const _hoisted_13 = { class: "block-password" }
const _hoisted_14 = { class: "flex-column w-100 mr-3" }
const _hoisted_15 = { class: "hints-list mt-3" }
const _hoisted_16 = ["disabled"]
const _hoisted_17 = {
  key: 1,
  class: "hint-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_form_group = _resolveComponent("b-form-group")!
  const _component_vue_multiselect = _resolveComponent("vue-multiselect")!
  const _component_b_form = _resolveComponent("b-form")!
  const _component_modal = _resolveComponent("modal")!
  const _component_UiErrorSnackbar = _resolveComponent("UiErrorSnackbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_modal, {
      id: _ctx.id,
      ref: "modal",
      "modal-show": _ctx.modalShow,
      title: _ctx.title,
      size: "lg",
      "ok-title": _ctx.$t('buttons.save'),
      "cancel-title": _ctx.$t('buttons.cancel'),
      "ok-disabled": _ctx.isSubmitAvailable,
      onShow: _ctx.resetModal,
      onHidden: _ctx.closeModal,
      onOk: _ctx.submitForm
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createTextVNode(" Email: "),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.selectedUser?.email), 1)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_b_form, {
                disabled: _ctx.disabled,
                onSubmit: _withModifiers(_ctx.submitForm, ["prevent"]),
                onReset: _ctx.resetForm
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_b_form_group, {
                      id: "input-group-last-name",
                      label: _ctx.$t('titles.last_name'),
                      "label-for": "input-last-name",
                      onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleInputOtherName($event)))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_form_input, {
                          id: "input-last-name",
                          ref: "inputLastName",
                          modelValue: _ctx.v$.form.lastName.$model,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$.form.lastName.$model) = $event)),
                          modelModifiers: { trim: true },
                          class: _normalizeClass({
                                        'invalid' : _ctx.v$.form.lastName.$error && !_ctx.selectedUser?.isExternal,
                                        'is-valid' : _ctx.v$.form.lastName.$dirty && !_ctx.v$.form.lastName.$error,
                                        'no-edit': _ctx.selectedUser?.isExternal
                                    }),
                          readonly: _ctx.selectedUser?.isExternal
                        }, null, 8, ["modelValue", "class", "readonly"]),
                        (_ctx.v$.form.lastName.$error && _ctx.isValidLastName && !_ctx.selectedUser?.isExternal)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t('modal_titles.minLength')), 1))
                          : (_ctx.v$.form.lastName.$error && _ctx.form.lastName && !_ctx.isValidLastName && !_ctx.selectedUser?.isExternal)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t('modal_titles.illegalCharacters')), 1))
                            : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_b_form_group, {
                      id: "input-group-first-name",
                      class: "first-name",
                      label: _ctx.$t('titles.first_name'),
                      "label-for": "input-first-name"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_form_input, {
                          id: "input-first-name",
                          ref: "inputFirstName",
                          modelValue: _ctx.v$.form.firstName.$model,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.v$.form.firstName.$model) = $event)),
                          modelModifiers: { trim: true },
                          type: "text",
                          class: _normalizeClass({
                                        'invalid' : _ctx.v$.form.firstName.$error && !_ctx.selectedUser?.isExternal,
                                        'is-valid' : _ctx.v$.form.firstName.$dirty && !_ctx.v$.form.firstName.$error,
                                        'no-edit': _ctx.selectedUser?.isExternal
                                    }),
                          readonly: _ctx.selectedUser?.isExternal,
                          onInput: _ctx.handleInputFirstName
                        }, null, 8, ["modelValue", "class", "readonly", "onInput"]),
                        (_ctx.v$.form.firstName.$error && _ctx.form.firstName && _ctx.isValidFirstName && !_ctx.selectedUser?.isExternal)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.$t('modal_titles.minLength')), 1))
                          : (_ctx.v$.form.firstName.$error && _ctx.form.firstName && !_ctx.isValidFirstName && !_ctx.selectedUser?.isExternal)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t('modal_titles.illegalCharacters')), 1))
                            : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_b_form_group, {
                      id: "input-group-middle-name",
                      label: _ctx.$t('titles.middle_name'),
                      "label-for": "input-middle-name",
                      onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleInputOtherName($event)))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_form_input, {
                          id: "input-middle-name",
                          ref: "inputMiddleName",
                          modelValue: _ctx.v$.form.middleName.$model,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.v$.form.middleName.$model) = $event)),
                          modelModifiers: { trim: true },
                          type: "text",
                          class: _normalizeClass({
                                        'invalid' : _ctx.v$.form.middleName.$error && !_ctx.selectedUser?.isExternal,
                                        'is-valid' : _ctx.v$.form.middleName.$dirty && !_ctx.v$.form.middleName.$error,
                                        'no-edit': _ctx.selectedUser?.isExternal
                                    }),
                          readonly: _ctx.selectedUser?.isExternal
                        }, null, 8, ["modelValue", "class", "readonly"]),
                        (_ctx.v$.form.middleName.$error && _ctx.form.middleName && _ctx.isValidMiddleName && !_ctx.selectedUser?.isExternal)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t('modal_titles.minLength')), 1))
                          : (_ctx.v$.form.middleName.$error && _ctx.form.middleName && !_ctx.isValidMiddleName && !_ctx.selectedUser?.isExternal)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.$t('modal_titles.illegalCharacters')), 1))
                            : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _createElementVNode("div", {
                    class: "form__link-on-card-person mb-5 mt-2",
                    onClick: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openCardPerson && _ctx.openCardPerson(...args)), ["stop"]))
                  }, " Открыть карточку участника "),
                  _createElementVNode("div", null, [
                    _createVNode(_component_b_form_group, {
                      id: "input-group-role",
                      label: _ctx.$t('role'),
                      "label-for": "input-role"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_vue_multiselect, {
                          modelValue: _ctx.form.grantedAccessGroups,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.grantedAccessGroups) = $event)),
                          "tag-placeholder": "Доступы",
                          placeholder: _ctx.$t('access_roles'),
                          label: "title",
                          "track-by": "id",
                          options: _ctx.allRoles,
                          multiple: true,
                          taggable: true,
                          searchable: true,
                          onTag: _ctx.addAccessGroups
                        }, null, 8, ["modelValue", "placeholder", "options", "onTag"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    (!_ctx.selectedUser?.isExternal)
                      ? (_openBlock(), _createBlock(_component_b_form_group, {
                          key: 0,
                          id: "input-group-password",
                          label: _ctx.$t('titles.password'),
                          "label-for": "input-password"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_13, [
                              _createElementVNode("div", _hoisted_14, [
                                _createVNode(_component_b_form_input, {
                                  id: "input-password",
                                  ref: "inputPassword",
                                  modelValue: _ctx.v$.form.password.$model,
                                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.v$.form.password.$model) = $event)),
                                  modelModifiers: { trim: true },
                                  type: "text",
                                  required: "",
                                  disabled: _ctx.isLoading,
                                  state: _ctx.validatePassword,
                                  onKeypress: _cache[8] || (_cache[8] = ($event: any) => (_ctx.noCyrillicChar($event)))
                                }, null, 8, ["modelValue", "disabled", "state"]),
                                _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('modal_titles.password_policy')), 1)
                              ]),
                              _createElementVNode("button", {
                                type: "button",
                                class: "btn btn-secondary",
                                disabled: _ctx.isLoading,
                                style: {height: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center'},
                                onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.generatePassword && _ctx.generatePassword(...args)))
                              }, _toDisplayString(_ctx.$t('buttons_common.generate')), 9, _hoisted_16)
                            ])
                          ]),
                          _: 1
                        }, 8, ["label"]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(_ctx.$t('modal_titles.message_for_ldap_users')), 1))
                  ])
                ]),
                _: 1
              }, 8, ["disabled", "onSubmit", "onReset"])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["id", "modal-show", "title", "ok-title", "cancel-title", "ok-disabled", "onShow", "onHidden", "onOk"]),
    _createVNode(_component_UiErrorSnackbar, { ref: "errorSnackbar" }, null, 512)
  ], 64))
}